import React, { Component } from "react";
import logoNo from "./logo_no.svg";
import logoEn from "./logo_en.svg";
import logoDa from "./logo_da.svg";
import logoSv from "./logo_sv.svg";
import "./App.scss";
import openSocket from "socket.io-client";

import { NativeTypes } from "react-dnd-html5-backend";
import { useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import ContentEditable from "react-contenteditable";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faSignOutAlt,
  faSpinner,
  faUmbrellaBeach,
  faCommentSlash,
  faCircleNotch,
  faUpload,
  faPaperclip,
  faCamera,
  faCaretUp,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

library.add(
  faClock,
  faSignOutAlt,
  faSpinner,
  faUmbrellaBeach,
  faCommentSlash,
  faCircleNotch,
  faUpload,
  faPaperclip,
  faCamera,
  faCaretUp,
  faFileUpload
);

const t = {
  no: require("./language/no.json"),
  en: require("./language/en.json"),
};

const logoList = {
  no: logoNo,
  en: logoEn,
  da: logoDa,
  sv: logoSv,
};

function logo(lang) {
  lang = lang.toLowerCase();
  if (logoList.hasOwnProperty(lang)) {
    return logoList[lang];
  }
  return logoList["no"];
}

function Preambel(props) {
  var queue = (
    <p className="showqueue">{t[props.language].welcomeYouAre + props.queuenr + t[props.language].welcomeInQueue}</p>
  );
  if (props.queuenr === 1) {
    queue = <p className="showqueue">{t[props.language].welcomeNextInQueue}</p>;
  }
  if (!props.connected) {
    queue = <p className="showqueue">{t[props.language].welcomeWaitingForConnection}</p>;
  }
  return (
    <section className="preamble">
      <h3>{t[props.language].welcome}</h3>
      <p>{t[props.language].welcomeStats}</p>
      {queue}
      <p>{t[props.language].welcomeBeginQuestion}</p>
    </section>
  );
}

function FormatMessage(props) {
  var contents;
  let text = props.text;

  let lines = text.split("\n");

  contents = lines.map((l, idx) => {
    let tokens = l.split(/\s/);
    let ml = tokens.map((token, i) => {
      let hasSpace = i !== tokens.length - 1;
      let maybeSpace = hasSpace ? " " : "";

      if (token.match(/^https?:\//)) {
        return (
          <a key={i} href={token} target="_blank" rel="noopener noreferrer">
            {token}
            {maybeSpace}
          </a>
        );
      } else {
        return token + maybeSpace;
      }
    });

    return (
      <div key={idx} class="line">
        {ml}
      </div>
    );
  });

  return contents;
}

class ChatMsgs extends Component {
  constructor(props) {
    super(props);

    this.scrollElement = React.createRef();
  }

  render() {
    const props = this.props;
    const el = props.messages;

    var list = [];
    var tmp;

    for (let idx = 0; idx < el.length; idx++) {
      let msg = el[idx];

      if (tmp) {
        if (tmp.author !== msg.author) {
          list.push(tmp);
          tmp = undefined;
        }
      }

      let direction;
      if (msg.author === "system") {
        direction = "system";
      } else {
        direction = msg.author === props.username ? "from" : "to";
      }

      if (!tmp)
        tmp = {
          messages: [],
          key: idx,
          date: null,
          author: msg.author,
          direction: direction,
        };

      tmp.date = msg.date;
      if (msg.hasOwnProperty("attachment")) {
        let filename = msg.attachment.substr(msg.attachment.lastIndexOf("/") + 1);
        tmp.messages.push(
          <div className="bubble" key={msg.key}>
            <a href={msg.attachment} target="_blank" rel="noopener noreferrer">
              <img src={msg.attachment} alt={filename} />
              <FontAwesomeIcon icon="paperclip" /> <span>{filename}</span>
              <div className="meta">
                <FontAwesomeIcon icon="clock" /> {msg.date}
              </div>
            </a>
          </div>
        );
      } else {
        tmp.messages.push(
          <div className="bubble" key={msg.key}>
            <FormatMessage text={msg.message} />
            <div className="meta">
              <FontAwesomeIcon icon="clock" /> {msg.date}
            </div>
          </div>
        );
      }
    }

    if (tmp) {
      list.push(tmp);
    }

    const chatItems = list.map((msg) => (
      <section key={msg.key} className={msg.direction + " chat"}>
        <div className="messages">{msg.messages}</div>
        <div className="meta">
          <FontAwesomeIcon icon="clock" /> {msg.date} - {msg.author}
        </div>
        <div className="pic"></div>
      </section>
    ));

    var closeditm;
    if (props.showended) {
      closeditm = <ClosedMessage username={props.username} socket={props.socket} language={props.language} />;
    }

    var self = this;

    setTimeout(function () {
      self.scrollElement.current.scrollTop = self.scrollElement.current.scrollHeight;
    }, 200);

    return (
      <div ref={this.scrollElement} className="chatcontainerwrapper">
        <div className="chatcontainer">
          <Preambel queuenr={props.queuenr} connected={props.connected} language={props.language} />
          {chatItems}
          {closeditm}
        </div>
      </div>
    );
  }
}

function Header(props) {
  let closebtn;

  if (props.showClose) {
    closebtn = (
      <button onClick={props.onClose} className="close">
        <FontAwesomeIcon icon="sign-out-alt" />
        <span style={{ fontSize: "0.7em", verticalAlign: "middle" }}>{t[props.language].closeButtonText}</span>
      </button>
    );
  }

  return (
    <header>
      <img src={logo(props.language)} className="logo" alt="logo" /> {closebtn}
    </header>
  );
}

function CloseConfirm(props) {
  return (
    <div className="fullback">
      <div className="modal">
        <div className="title">{t[props.language].quitChat}</div>
        <div className="message">{t[props.language].quitConfirmQuit}</div>
        <div className="buttons">
          <button className="ok" onClick={props.onOk}>
            {t[props.language].Yes}
          </button>
          <button className="cancel" onClick={props.onCancel}>
            {t[props.language].No}
          </button>
        </div>
      </div>
    </div>
  );
}

function DropZone(props) {
  const accept = NativeTypes.FILE;

  const onDrop = function (item) {
    if (item.files && item.files.length === 0) {
      //props.onError();
      return;
    }

    if (!item.files[0].type.startsWith("image/")) {
      //props.onError();
      return;
    }

    let data = new FormData();
    data.append("chatid", props.channel);
    data.append("attachment", item.files[0]);
    axios
      .post("/upload", data)
      .then(function (res) {
        //props.onSuccess();
      })
      .catch(function (err) {
        //props.onError();
      });
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  let display = "none";

  if (canDrop) {
    display = "";
  }

  let myClass = "dropZone";
  if (isOver) {
    myClass += " over";
  }

  return (
    <div ref={drop} className={myClass} style={{ display }}>
      <FontAwesomeIcon icon={faFileUpload} />
    </div>
  );
}

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      writeTimeOut: null,
      writenotice: false,
      uploadInProgres: false,
      screenShotInProgres: false,
      showUploadList: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.takeScreenShot = this.takeScreenShot.bind(this);
    this.handleUploadList = this.handleUploadList.bind(this);
  }

  handleChange(event) {
    let self = this;
    if (this.state.writeTimeOut) {
      clearTimeout(this.state.writeTimeOut);
    }
    if (!this.state.writenotice) {
      this.props.socket.emit("writingstart", {});
      this.setState({ writenotice: true });
    }
    let timer = setTimeout(() => {
      self.props.socket.emit("writingend", {});
      self.setState({ writenotice: false });
    }, 10000);

    var txt = event.target.value;
    if (this.hasUnwantedTags(txt)) {
      txt = this.cleanHTML(txt);
    }
    this.setState({ value: txt, writeTimeOut: timer });
  }

  hasUnwantedTags(html) {
    const regexp = RegExp("<.*?>", "g");
    const allowedTags = ["<p>", "</p>", "<div>", "</div>", "<br>", "<br />"];
    let matches;
    while ((matches = regexp.exec(html)) !== null) {
      if (allowedTags.indexOf(matches[0]) === -1) {
        return true;
      }
    }
  }

  cleanHTML(html) {
    html = html.replace(/<(p|div)>/gi, "\n");
    html = html.replace(/<br(| \/)>/gi, "\n");
    html = html.replace(/<\/(p|div)>/gi, "");
    html = html.replace(/<[^>]*>/gi, "");
    html = html.replace(/\n/g, "<br>");
    return html;
  }

  handleEnter(event) {
    if (!this.props.inchat) return;
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      this.handleSubmit(event);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.props.inchat) return;
    if (this.state.writeTimeOut) {
      clearTimeout(this.state.writeTimeOut);
    }
    this.props.socket.emit("writingend", {});
    this.setState({ writenotice: false });
    if (this.state.value !== "") {
      let tmpEl = document.createElement("textarea");
      let txt = this.cleanHTML(this.state.value);
      tmpEl.innerHTML = txt.replace(/<br(| \/)>/gi, "\n").trim();
      this.props.socket.emit("message", { data: tmpEl.value });
      this.setState({ value: "" });
    }
  }

  handleUpload(event) {
    var self = this;
    self.setState({ showUploadList: false });
    var filebox = document.createElement("input");
    filebox.setAttribute("type", "file");
    filebox.setAttribute("accept", ".jpg,.jpeg,.png,.gif,image/*");
    filebox.addEventListener("change", (ev) => {
      let f = filebox.files;
      if (f.length > 0) {
        if (!f[0].type.startsWith("image/")) {
          alert(t[self.props.language].uploadNotAnImage);
          return;
        }
        if (f[0].size > 10000000) {
          alert(t[self.props.language].uploadTooLarge);
          return;
        }
        self.setState({ uploadInProgres: true });
        let data = new FormData();
        data.append("chatid", this.props.channel);
        data.append("attachment", f[0]);
        axios
          .post("/upload", data)
          .then(function (res) {
            self.setState({ uploadInProgres: false });
          })
          .catch(function (err) {
            self.setState({ uploadInProgres: false });
          });
      }
    });
    filebox.click();
    let f = filebox.files;
  }

  isScreenShotAvailable() {
    // Safari is a bit wibbly wobbly timey wimey
    let isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf("Apple") > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf("CriOS") === -1 &&
      navigator.userAgent.indexOf("FxiOS") === -1;
    if (isSafari) {
      return false;
    }
    if (navigator.mediaDevices.getDisplayMedia || navigator.getDisplayMedia) {
      return true;
    }
    return false;
  }

  takeScreenShot() {
    var prom;
    var self = this;
    self.setState({ showUploadList: false });
    self.setState({ uploadInProgres: true });
    if (navigator.getDisplayMedia) {
      prom = navigator.getDisplayMedia({
        video: true,
      });
    }
    if (navigator.mediaDevices.getDisplayMedia) {
      prom = navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
    }
    if (!prom) {
      return null;
    }

    // Edge don't have this function, add with this polyfill.
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
        value: function (callback, type, quality) {
          var canvas = this;
          setTimeout(function () {
            var binStr = atob(canvas.toDataURL(type, quality).split(",")[1]),
              len = binStr.length,
              arr = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            callback(new Blob([arr], { type: type || "image/png" }));
          });
        },
      });
    }

    // permission granted:
    prom
      .then(function (stream) {
        var video = document.createElement("video");
        video.setAttribute("autoplay", "autoplay");
        video.className = "screenshot";
        document.body.appendChild(video);
        video.srcObject = stream;
        video.addEventListener("play", function () {
          setTimeout(() => {
            var context;
            var width = video.offsetWidth,
              height = video.offsetHeight;

            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;

            context = canvas.getContext("2d");
            context.drawImage(video, 0, 0, width, height);

            canvas.toBlob((blob) => {
              let data = new FormData();
              data.append("chatid", self.props.channel);
              data.append("attachment", blob, "screenshot-" + ((new Date() / 1000) | 0) + ".png");
              axios
                .post("/upload", data)
                .then(function (res) {
                  self.setState({ uploadInProgres: false });
                })
                .catch(function (err) {
                  self.setState({ uploadInProgres: false });
                });
            });

            let tracks = video.srcObject.getTracks();
            tracks.forEach((track) => track.stop());
            video.srcObject = null;
            document.body.removeChild(video);
            video = null;
          }, 1000);
        });
      })
      // permission denied:
      .catch(function (error) {
        alert("Could not get access to the screen shot.");
        self.setState({ uploadInProgres: false });
      });
  }

  handleUploadList(event) {
    this.setState({ showUploadList: !this.state.showUploadList });
  }

  render() {
    var uploadBtn, attachmentButtonSelected;
    var self = this;

    uploadBtn = null;
    if (this.state.showUploadList) {
      uploadBtn = (
        <div class="uploadlist">
          <button onClick={this.handleUpload}>
            <FontAwesomeIcon icon="upload" /> {t[this.props.language].uploadImage}
          </button>
          <button
            title={!self.isScreenShotAvailable() ? t[self.props.language].uploadYourBrowserDoNotSupport : null}
            disabled={!self.isScreenShotAvailable()}
            onClick={this.takeScreenShot}
          >
            <FontAwesomeIcon icon="camera" /> {t[self.props.language].uploadScreensShot}
          </button>
        </div>
      );
      attachmentButtonSelected = "attachmentButtonSelected";
    }

    var arrowUp;
    if (!this.state.uploadInProgres) {
      arrowUp = <FontAwesomeIcon className="arrowup" icon="caret-up" />;
    }

    return (
      <footer>
        <section id="status">{this.props.statusMsg}</section>
        <form onSubmit={this.handleSubmit}>
          <div id="sendmessagecontainer">
            <button id="sendmessage" type="submit" disabled={this.props.inchat ? "" : "disabled"}>
              {t[self.props.language].chatSend}
            </button>
            {uploadBtn}
            <button
              id="sendattachment"
              type="button"
              className={attachmentButtonSelected}
              disabled={this.props.inchat && !this.state.uploadInProgres ? "" : "disabled"}
              onClick={this.handleUploadList}
            >
              <FontAwesomeIcon
                className="addpadding"
                icon={this.state.uploadInProgres ? "circle-notch" : "paperclip"}
                spin={this.state.uploadInProgres}
              />
              &nbsp;{arrowUp}
            </button>
          </div>
          <div id="messagecontainer">
            <ContentEditable
              id="message"
              disabled={this.props.ended}
              onChange={this.handleChange}
              html={this.state.value}
              onKeyDown={this.handleEnter}
            />
          </div>
        </form>
      </footer>
    );
  }
}

class ClosedMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      help: null,
      use: null,
      comment: null,
      logSendt: false,
      responseSaved: false,
    };

    this.handleChangeHelp = this.handleChangeHelp.bind(this);
    this.handleChangeUse = this.handleChangeUse.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);
    this.handleSendResponse = this.handleSendResponse.bind(this);
    this.handleSendLog = this.handleSendLog.bind(this);
  }

  handleChangeHelp(event) {
    this.setState({ help: event.target.value });
  }

  handleChangeUse(event) {
    this.setState({ use: event.target.value });
  }

  handleChangeComment(event) {
    this.setState({ comment: event.target.value });
  }

  handleSendResponse(event) {
    this.setState({ responseSaved: true });
    this.props.socket.emit("sendresponse", this.state);
  }

  handleSendLog(event) {
    this.setState({ logSendt: true });
    this.props.socket.emit("sendlogasemail", {});
  }

  render() {
    var sendLog = (
      <p>
        {t[this.props.language].endedSendCopy} {this.props.username}?{" "}
        <button onClick={this.handleSendLog}>{t[this.props.language].endedClickHere}</button>
      </p>
    );
    if (this.state.logSendt) {
      sendLog = <p>{t[this.props.language].endedCopySent}</p>;
    }

    var responseForm = (
      <table>
        <tr>
          <td colspan="7" className="tableheader">
            {t[this.props.language].endedFeedbackSatisfactionWithHelp}
          </td>
        </tr>
        <tr>
          <td>{t[this.props.language].endedFeedbackBad}</td>
          <td>
            <input type="radio" name="help" value="1" onClick={this.handleChangeHelp} />
          </td>
          <td>
            <input type="radio" name="help" value="2" onClick={this.handleChangeHelp} />
          </td>
          <td>
            <input type="radio" name="help" value="3" onClick={this.handleChangeHelp} />
          </td>
          <td>
            <input type="radio" name="help" value="4" onClick={this.handleChangeHelp} />
          </td>
          <td>
            <input type="radio" name="help" value="5" onClick={this.handleChangeHelp} />
          </td>
          <td>{t[this.props.language].endedFeedbackGood}</td>
        </tr>

        <tr>
          <td colspan="7" className="tableheader">
            {t[this.props.language].endedFeedbackSatisfactionWithChat}
          </td>
        </tr>
        <tr>
          <td>{t[this.props.language].endedFeedbackBad}</td>
          <td>
            <input type="radio" name="use" value="1" onClick={this.handleChangeUse} />
          </td>
          <td>
            <input type="radio" name="use" value="2" onClick={this.handleChangeUse} />
          </td>
          <td>
            <input type="radio" name="use" value="3" onClick={this.handleChangeUse} />
          </td>
          <td>
            <input type="radio" name="use" value="4" onClick={this.handleChangeUse} />
          </td>
          <td>
            <input type="radio" name="use" value="5" onClick={this.handleChangeUse} />
          </td>
          <td>{t[this.props.language].endedFeedbackGood}</td>
        </tr>

        <tr>
          <td colspan="7" className="tableheader">
            {t[this.props.language].endedFeedbackNote}
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <textarea onChange={this.handleChangeComment}></textarea>
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <button onClick={this.handleSendResponse}>{t[this.props.language].endedFeedbackSend}</button>
          </td>
        </tr>
      </table>
    );
    if (this.state.responseSaved) {
      responseForm = <p>{t[this.props.language].endedFeadbackThankYou}</p>;
    }

    return (
      <section className="preamble">
        <h3>{t[this.props.language].ended}</h3>
        {sendLog}
        <p>{t[this.props.language].endedGiveUsFeedback}</p>
        {responseForm}
      </section>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    var code = window.location.hash.substr(1);

    this.state = {
      state: "",
      stateMsg: t["no"].connecting,
      queuenr: 10,
      waiting: true,
      language: "no",
      connected: false,
      showended: false,
      confirmClose: false,
      list: [],
      inchat: false,
      error: null,
      writing: null,
      channel: code,
      socket: openSocket("/"),
    };

    this.dingAudio = new Audio("/ding.mp3");

    this.handleClose = this.handleClose.bind(this);
    this.handleConfirmClose = this.handleConfirmClose.bind(this);
    this.handleCancelClose = this.handleCancelClose.bind(this);

    var hidden = "hidden";
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
    }

    let self = this;
    this.state.socket.on("allMessages", (messages) => {
      self.setState({ list: messages });
    });

    this.state.socket.on("message", (message) => {
      if (message.author === "system") {
        message.direction = "system";
      } else {
        message.direction = message.author === this.state.username ? "from" : "to";
      }
      self.setState({ list: [...self.state.list, message] });
      if (document[hidden]) {
        self.dingAudio.play();
      }
    });

    this.state.socket.on("state", (state) => {
      self.setState(state);
    });

    this.state.socket.on("writing", (data) => {
      if (data.author === this.state.username) return;
      self.setState({ writing: data.writing ? data.author : null });
    });

    this.state.socket.on("apperror", function (data) {
      if (data.code === 404) {
        self.setState({ error: 404 });
        if (data.language) {
          self.setState({ language: data.language });
        }
        self.state.socket.close();
      }
      if (data.code === 410) {
        self.setState({ error: 410 });
        if (data.language) {
          self.setState({ language: data.language });
        }
        self.state.socket.close();
      }
    });

    this.state.socket.on("connected", function () {
      self.state.socket.emit("register", {
        ssid: self.state.channel,
      });
    });

    this.state.socket.on("chatended", function () {
      self.setState({ inchat: false });
      self.setState({ showended: true });
    });

    this.state.socket.on("disconnect", function (r) {
      if (r === "ping timeout") this.state.socket.connect();
    });
  }

  handleConfirmClose(event) {
    this.setState({ showCloseForm: false });
    this.state.socket.emit("close", {});
    event.preventDefault();
  }

  handleCancelClose(event) {
    this.setState({ showCloseForm: false });
    event.preventDefault();
  }

  handleClose(event) {
    if (this.state.showended) return;
    this.setState({ showCloseForm: true });
    //    this.state.socket.emit('close', {});
    event.preventDefault();
  }

  render() {
    var state = this.state;
    var self = this;

    if (state.connected === false && state.error == null && state.showended === false) {
      return (
        <div id="content" className="content">
          <div className="fullpage">
            <div className="icon">
              <FontAwesomeIcon icon="spinner" spin />
            </div>
            <div className="title">{t[self.state.language].connecting}</div>
          </div>
        </div>
      );
    }

    if (state.error === 410) {
      return (
        <div id="content" className="content">
          <div className="fullpage">
            <div className="icon">
              <FontAwesomeIcon icon="umbrella-beach" />
            </div>
            <div className="title">{t[self.state.language].closed} </div>
            <div className="message">
              {t[self.state.language].closedChatIsClosed}
              <br />
              {t[self.state.language].chatSendUsEmail}
            </div>
          </div>
        </div>
      );
    }

    if (state.error === 404) {
      return (
        <div id="content" className="content">
          <div className="fullpage">
            <div className="icon">
              <FontAwesomeIcon icon="comment-slash" />
            </div>
            <div className="title">{t[self.state.language].notFound}</div>
            <div className="message">
              {t[self.state.language].notFoundLinkIsInvalid}
              <br />
              {t[self.state.language].notFoundTryAgainLater}
            </div>
          </div>
        </div>
      );
    }

    let sMsg;

    if (state.connected) {
      if (this.state.writing) {
        sMsg = this.state.writing + t[self.state.language].chatWriting;
      } else {
        if (state.showended) {
          sMsg = t[self.state.language].chatEnded;
        } else {
          sMsg = t[self.state.language].chatConnected;
        }
      }
      if (state.waiting) {
        sMsg = t[self.state.language].chatWaiting;
      }
    }

    let showCloseForm;

    if (this.state.showCloseForm) {
      showCloseForm = (
        <CloseConfirm onOk={this.handleConfirmClose} onCancel={this.handleCancelClose} language={self.state.language} />
      );
    }

    let showCloseBtn = !state.showended && !state.waiting;

    return (
      <div id="content" className="content">
        <Header onClose={self.handleClose} showClose={showCloseBtn} language={self.state.language} />
        <main>
          <ChatMsgs
            username={state.username}
            socket={state.socket}
            connected={state.connected}
            queuenr={state.queuenr}
            inchat={state.inchat}
            messages={state.list}
            showended={state.showended}
            language={state.language}
          />
        </main>
        <Footer
          statusMsg={sMsg}
          socket={state.socket}
          inchat={state.inchat}
          ended={state.showended}
          channel={state.channel}
          language={state.language}
        />
        {showCloseForm}
        <DndProvider backend={HTML5Backend}>
          <DropZone channel={state.channel} />
        </DndProvider>
      </div>
    );
  }
}

export default App;
